import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Box, Snackbar, Alert } from '@mui/material';
import { authenticatedFetch } from '../utils/utils';
import ViolationsList from '../components/ViolationsList';

export default function Violations() {
    const { studentId } = useParams();
    const [violations, setViolations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedViolation, setSelectedViolation] = useState(null);
    const [generalError, setGeneralError] = useState('');
    const [actionType, setActionType] = useState('');
    const [student, setStudent] = useState(null);

    useEffect(() => {
        fetchViolations();
    }, [studentId]);

    const fetchViolations = async () => {
        setLoading(true);
        try {
            const today = new Date().toISOString().split('T')[0];
            const response = await authenticatedFetch(`/violations/for-student/${studentId}/${today}`);
            if (response.ok) {
                const data = await response.json();
                setViolations(data.violations);
                setStudent(data.student);
            } else {
                console.error('Failed to fetch violations');
                setGeneralError('Failed to fetch violations');
            }
        } catch (error) {
            console.error('Error fetching violations:', error);
            setGeneralError('Error fetching violations');
        } finally {
            setLoading(false);
        }
    };

    const handleMarkPaid = (violation) => {
        setSelectedViolation(violation);
        if (!violation.paid) {
            setActionType('markPaid');
        } else {
            setActionType('unmarkPaid');
        }
        setModalOpen(true);
    };

    const handleModalClose = () => {
        setModalOpen(false);
        setSelectedViolation(null);
        setActionType('');
    };

    const handleAction = async () => {
        try {
            const endpoint = actionType === 'markPaid' ? 'mark-paid' : 'unmark-paid';
            const response = await authenticatedFetch(`/violations/${selectedViolation.id}/${endpoint}`, {
                method: 'PUT'
            });
            if (response.ok) {
                await fetchViolations();
                handleModalClose();
            } else {
                throw new Error(`Failed to ${actionType === 'markPaid' ? 'mark' : 'unmark'} violation as paid`);
            }
        } catch (error) {
            console.error(`Error ${actionType === 'markPaid' ? 'marking' : 'unmarking'} violation as paid:`, error);
            setGeneralError(`Failed to ${actionType === 'markPaid' ? 'mark' : 'unmark'} violation as paid`);
        }
    };

    return (
        <div>
            {loading ? (
                <h1>Loading...</h1>
            ) : (
                <h1>Fines for {student?.nickname}</h1>
            )}
            {loading ? (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    background: 'rgba(0, 0, 0, 0.3)',
                    zIndex: 'tooltip',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress />
                </Box>
            ) : (
                <ViolationsList
                    violations={violations}
                    onMarkPaid={handleMarkPaid}
                    onUnmarkPaid={handleMarkPaid}
                    modalOpen={modalOpen}
                    selectedViolation={selectedViolation}
                    actionType={actionType}
                    onModalClose={handleModalClose}
                    handleAction={handleAction}
                />
            )}
            {generalError && (
                <Snackbar open={!!generalError} autoHideDuration={6000} onClose={() => setGeneralError('')}>
                    <Alert onClose={() => setGeneralError('')} severity="error" sx={{ width: '100%' }}>
                        {generalError}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
}
