// src/App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Box } from '@mui/material';
import Navigation from './components/Navigation';
import Attendance from './views/Attendance';
import OffCampus from './views/OffCampus';
import People from './views/People';
import LoginForm from './components/LoginForm';
import OffCampusDestinations from './views/OffCampusDestinations';
import Calendar from './views/Calendar';
import Violations from './views/Violations';
import StudentAttendance from './views/StudentAttendance';
import ViolationsPeople from './views/ViolationsPeople';

function App() {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [isAdmin, setIsAdmin] = useState(JSON.parse(localStorage.getItem('isAdmin')) || false);

  useEffect(() => {
    localStorage.setItem('isAdmin', JSON.stringify(isAdmin));
  }, [isAdmin]);

  useEffect(() => {
    const handleAuthRequired = () => {
      setShowLoginForm(true);
    }

    window.addEventListener('authRequired', handleAuthRequired);

    return () => {
      window.removeEventListener('authRequired', handleAuthRequired);
    };
  }, []);

  const toggleAdminMode = (mode) => {
    setIsAdmin(mode); // Function to toggle admin mode
  };

  const handleLoginSuccess = () => {
    setShowLoginForm(false);
    window.location.pathname = '/attendance';
  };

  return (
    <Router>
      <Navigation toggleAdminMode={toggleAdminMode} isAdmin={isAdmin} />
      <Box sx={{ margin: 3 }}> {/* You can adjust the margin as needed */}
        <Routes>
          <Route path="/attendance" element={<Attendance isAdmin={isAdmin} />} />
          <Route path="/attendance/:date" element={<Attendance isAdmin={isAdmin} />} />
          <Route path="/off-campus" element={<OffCampus isAdmin={isAdmin} />} />
          <Route path="/off-campus/:date" element={<OffCampus isAdmin={isAdmin} />} />
          <Route path="/violations" element={<ViolationsPeople />} />
          <Route path="/violations/:studentId" element={<Violations />} />
          <Route path="/people" element={<People />} />
          <Route path="/destinations" element={<OffCampusDestinations />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/" element={<Navigate replace to="/attendance" />} />
          <Route path="/student-attendance/:studentId/:date?" element={<StudentAttendance isAdmin={isAdmin} />} />
        </Routes>
      </Box>
      {showLoginForm && <LoginForm open={showLoginForm} onClose={() => setShowLoginForm(false)} onLogin={handleLoginSuccess} /> }
    </Router>
  );
}

export default App;
