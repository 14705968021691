import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Box, CircularProgress, Typography, Snackbar, Alert
} from '@mui/material';
import { authenticatedFetch } from '../utils/utils';

export default function ViolationsPeople() {
    const [people, setPeople] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        fetchPeopleWithViolations();
    }, []);

    const fetchPeopleWithViolations = async () => {
        setLoading(true);
        try {
            const today = new Date().toISOString().split('T')[0];
            const response = await authenticatedFetch(`/violations/people/${today}`);
            if (response.ok) {
                const data = await response.json();
                setPeople(data);
            } else {
                throw new Error('Failed to fetch people with fines');
            }
        } catch (error) {
            console.error('Error fetching people with fines:', error);
            setError('Failed to fetch people with fines');
        } finally {
            setLoading(false);
        }
    };

    const handleRowClick = (studentId) => {
        navigate(`/violations/${studentId}`);
    };

    return (
        <div>
            <Typography variant="h5" gutterBottom>Fines</Typography>
            {loading ? (
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '200px'
                }}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="fines table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Unpaid Amount</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {people.map((person) => (
                                <TableRow 
                                    key={person.id}
                                    onClick={() => handleRowClick(person.id)}
                                    sx={{
                                        cursor: 'pointer',
                                        '&:hover': {
                                            backgroundColor: '#f5f5f5',
                                        },
                                    }}
                                >
                                    <TableCell sx={{ 
                                        color: person.unpaid_amount > 0 ? 'red' : 'inherit'
                                    }}>
                                        {person.nickname}
                                    </TableCell>
                                    <TableCell sx={{ 
                                        color: person.unpaid_amount > 0 ? 'red' : 'inherit'
                                    }}>
                                        ${person.unpaid_amount}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
            {error && (
                <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                    <Alert onClose={() => setError('')} severity="error" sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
}
